import React from 'react'
import styled from 'styled-components'
import App from 'App'
import { transparentize } from 'polished'
import { colors, COLOR_CONSTANTS } from 'theme'
import SEO from 'components/SEO'
import { Box, Grid, Flex } from 'components/Layout'
import { H1, H3, H2, Text } from 'components/Typography'
import Hero from 'components/Hero'
import BgImg from 'components/BgImage'
import Button from 'components/Button'
import Container from 'components/Container'
import Footer from 'components/Footer'
import LocalImage from 'components/LocalImage'
import AgenciesCounter from 'components/AgenciesCounter'
import ImageText from 'routes/About/ImageText'
import Link from 'components/Link'
import bgImage from 'static/images/bg_partnership.png'
import badge from 'static/images/badge.png'
import partnershipSendibleLogo from 'static/images/partnership_sendible_logo.png'
import partnership1 from 'static/images/partnership1.png'
import partnershipHand from 'static/images/partnership_hand.png'
import partnershipConfiguration from 'static/images/partnership_configuration.png'
import sendibleBadge from 'static/images/sendible/badge.svg'

const StyledLink = styled(Link)`
  display: inline;
`

const StyledButtonPrimary = styled(Button.Primary)`
  &:hover {
    p {
      color: ${colors.primary};
    }
    background-color: ${colors.white};
  }
`

const StyledButtonReversed = styled(Button.Reversed)`
  &:hover {
    p {
      color: ${colors.white};
    }
    background-color: ${colors.primary};
  }
`

const StyledFlex = styled(Flex)`
  & > * {
    border-bottom: 1px solid ${COLOR_CONSTANTS.SOLITUDE};
  }
  & > *:first-child {
    border-top: 1px solid ${COLOR_CONSTANTS.SOLITUDE};
  }
`

const StyledH3 = styled(H3)`
  opacity: 0.8;
`

const IMAGE_TEXT_CONTENT = [
  {
    title: 'Agency Vista Partner Program',
    body:
      'The Agency Vista Partner Program is for agencies and service providers looking to expand their offerings and increase their exposure. Whether your expertise is marketing, sales, customer service, web design, CRM, or IT services—if you want to accelerate your growth, we want to help.',
    image: badge,
    button: 'Learn about Agency Vista verified badge',
    buttonLink: '/badge',
  },
]

const StyledImage = styled.img`
  pointer-events: none;
  max-width: 100%;
  object-fit: contain;
  z-index: 1;
`

const MOCK_CONTENT = [
  {
    title: 'Here Is How To Get Started',
    body: `If your agency has a marketing partner or a certification badge, it tells everyone you're among the best at what you do. Displaying your badge on Agency Vista will be key in driving more exposure to your agency while generating new business opportunities.`,
    image: partnership1,
    template: (
      <Flex flexDirection="column" mt="m" key="template">
        <StyledFlex flexDirection="column">
          <Box py="s">
            <Text color="ternaryText" fontSize="xl" opacity={0.9} lineHeight="xl">
              1.{' '}
              <StyledLink href="/signup" color="primary" fontSize="xl">
                Sign up
              </StyledLink>{' '}
              to Agency Vista or{' '}
              <StyledLink href="/signup" color="primary" fontSize="xl">
                log in
              </StyledLink>{' '}
              to your profile.
            </Text>
          </Box>
          <Box py="s">
            <Text fontSize="xl" opacity={0.9} lineHeight="xl" py="xs" color="ternaryText">
              2. Select{' '}
              <StyledLink href="/badge" color="primary" fontSize="xl">
                badges
              </StyledLink>{' '}
              on the top menu bar and choose the partnership program you are part of.
            </Text>
          </Box>
          <Box py="s">
            <Text fontSize="xl" opacity={0.9} lineHeight="xl" py="xs" color="ternaryText">
              3. Verify your badge information and have it automatically connected to your profile.
            </Text>
          </Box>
        </StyledFlex>
        <Box mt="m" as="a" href="/signup">
          <Button.Primary>
            <Text fontSize="s">Connect Your Badges</Text>
          </Button.Primary>
        </Box>
      </Flex>
    ),
  },
]

const Partnership = () => (
  <App>
    <SEO
      title="Amplify Your Credibility Through Marketing Partner Badges"
      description="Connect your partnership badges and certification to showcase your expertise as you grow your business. Have your own partner program? Expand your partnership badge offering by launching it in our ecosystem of 39,000+ trusted marketers."
      path="/partnership/"
      image="/img/discover_perfect_social_agency.jpg"
    />
    <Hero>
      <BgImg src={bgImage} />
      <H1 color="white">Amplify credibility with Agency Partner Badges</H1>
      <Box mt="m" maxWidth="800px">
        <StyledH3 color="white">
          Connect your partnership badges and certification to showcase your agency expertise or launch you partner
          program in our ecosystem of <AgenciesCounter fontSize="xxl" as="span" color="white" /> trusted and verified
          marketing agencies.
        </StyledH3>
      </Box>
    </Hero>
    <Container maxWidth="1080px">
      <Box mt="xl">
        {IMAGE_TEXT_CONTENT.map((content, index) => (
          <Box key={content.title} mb="xxl">
            <ImageText isReversed={index % 2 !== 0} isSecondBubbleVisible={index % 2 === 0} {...content} />
          </Box>
        ))}
      </Box>
    </Container>
    <Grid mt="l" gridTemplateColumns={['1fr', 'repeat(2, 1fr)']} backgroundColor="none">
      <Flex
        backgroundColor={transparentize(0.5, COLOR_CONSTANTS.SOLITUDE)}
        py="xl"
        justifyContent={['center', 'flex-end']}
      >
        <Flex
          flexDirection="column"
          alignItems={['center', 'flex-start']}
          pb="xl"
          position="relative"
          maxWidth="540px"
          px="m"
        >
          <LocalImage
            source={partnershipConfiguration}
            width="60px"
            height="60px"
            alt="Agency Vista for Tools Partner Program"
          />
          <Box minHeight={{ tablet: '85px' }} mt="l" mb="m">
            <H2 fontWeight="bold" textAlign={['center', 'left']}>
              Partner Programs
            </H2>
          </Box>
          <Text color="ternaryText" fontSize="l" textAlign={['center', 'left']} mb="l">
            Agency Vista enables platforms to launch partnership programs for their marketing agency customers and award
            them with verifiable badges. When the agencies join this program they also join a growing ecosystem of{' '}
            <AgenciesCounter as="span" color="primary" fontSize="l" /> other agencies on Agency Vista. These agencies
            then get to build up their profile beyond just a partner badge.
          </Text>
          <Box position="absolute" bottom="0">
            <StyledButtonPrimary as={Link} href="mailto:yourfriends@agencyvista.com?subject=Launch%20Partner%20Program">
              <Text fontWeight="medium">Launch your partner program</Text>
            </StyledButtonPrimary>
          </Box>
        </Flex>
      </Flex>
      <Flex backgroundColor={COLOR_CONSTANTS.SOLITUDE} py="xl" justifyContent={['center', 'flex-start']}>
        <Flex
          flexDirection="column"
          alignItems={['center', 'flex-end']}
          pb="xl"
          position="relative"
          maxWidth="540px"
          px="m"
        >
          <LocalImage source={partnershipHand} width="60px" height="60px" alt="Agency Partner Program" />
          <Box minHeight={{ tablet: '85px' }} mt="l" mb="m">
            <H2 fontWeight="bold" textAlign={['center', 'right']}>
              Agency Partner Badges
            </H2>
          </Box>
          <Text color="ternaryText" fontSize="l" textAlign={['center', 'right']} mb="l">
            Increase your agency profile visibility by connecting your partner badges and certifications to increase
            your profile credibility and unlock new high-traffic placements on Agency Vista. Stand out from the
            competition by showcasing your expertise and attract new customers.
          </Text>
          <Box position="absolute" bottom="0">
            <StyledButtonPrimary as={Link} href="/signup">
              <Text fontWeight="medium">Connect your partner badges</Text>
            </StyledButtonPrimary>
          </Box>
        </Flex>
      </Flex>
    </Grid>
    <Container maxWidth="1080px">
      <Flex mt="xl" px="m" flexDirection="column" alignItems="center" textAlign="center">
        <H2 textAlign="center" mb="l">
          Featured Platform Partner Program
        </H2>
        <Flex
          width="100%"
          maxWidth={{ mobile: '180px', desktop: '250px' }}
          order={[1, 2]}
          mt={{ mobile: 'l', desktop: '0' }}
          justifyContent="center"
          position="relative"
        >
          <Box mt="l" as="a" href="/sendible">
            <StyledImage
              src={sendibleBadge}
              alt="Sendible Social Media Management Platform"
              width="100%"
              height="100%"
            />
          </Box>
        </Flex>
      </Flex>
      <Box mt="xxl">
        {MOCK_CONTENT.map((content, index) => (
          <Box key={content.title} mb="xxl">
            <ImageText isReversed={index % 2 !== 1} isSecondBubbleVisible={index !== 1} {...content} />
          </Box>
        ))}
      </Box>
    </Container>
    <Flex
      color={colors.white}
      px="l"
      my="xl"
      py="xl"
      flexDirection="column"
      alignItems="center"
      fontWeight="medium"
      backgroundColor={colors.primary}
    >
      <H2 textAlign="center">Looking for a marketing agency?</H2>
      <Box maxWidth="768px" px="m">
        <Text mt="m" lineHeight="150%" fontSize="l" textAlign="center" whiteSpace="pre-line">
          We have <AgenciesCounter fontSize="l" as="span" /> agencies who can help! Explore our global marketing agency
          ecosystem of businesses that can work with you to scale your growth.
        </Text>
      </Box>
      <Box mt="l">
        <Button.Reversed as={Link} href="/find-agency" fontSize="s">
          Find an Agency
        </Button.Reversed>
      </Box>
    </Flex>
    <Footer />
  </App>
)

export default Partnership
